<template>
	<div v-if="(!conditionalDisplay.isMobileApp && canDelete) || (conditionalDisplay.isMobileApp && canDelete && model.length == 1 && model[0].is_document)">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
			<w-btn class="mr-1" flat icon="delete" mini v-bind="$attrs" @click="doShowDeleteDialog()"> {{ text }}</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay" avatar @click="doShowDeleteDialog()">
			<v-list-tile-avatar>
				<v-icon color="primary">delete</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ text }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
		<ItemDeletion v-if="showDeleteDialog" v-model="model" @close="closeDialog()" />
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

const name = 'DeleteButton';

export default {
	name,
	components: {
		ItemDeletion: () => ({
			component: import('@/components/Documents/Actions/Delete/ItemDeletion')
		})
	},
	mixins: [DocumentActionModuleGuard],
	props: {
		openWithEvent: {
			default: false,
			required: false,
			type: Boolean
		},
		selectedFolder: {
			default: null,
			required: false,
			type: Object
		},
		value: {
			default: () => [],
			required: false,
			type: [Object, Array]
		}
	},
	data: function () {
		return {
			showDeleteDialog: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			currentDocument: state => state.documents.preview.current,
			pauseKeyboardListening: state => state.pauseKeyboardListening,
			currentDialog: state => state.dialogs.current
		}),
		model: {
			get: function () {
				if (this.currentDocument?.id) {
					return [this.currentDocument];
				}

				return Array.isArray(this.value) ? this.value : [this.value] || []
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		canDelete: function () {
			return (
				!this.isGuest &&
				this.model.length > 0 &&
				this.model.every(
					node =>
						(!this.selectedFolder || node.id != this.selectedFolder.id) &&
						!node.is_readonly &&
						!node.isValidated &&
						((node.is_folder && !node.is_trash && !node.is_default) ||
							(node.is_folder && !node.is_trash && node.is_default && node.is_manually_replicable) ||
							(!node.is_folder &&
								(!node.hasOwnProperty('versions') ||
									(node.versions.length > 0 &&
										node.versions.every(version => version.hasOwnProperty('validated_at')) &&
										node.versions.every(version => version.validated_at == null)))))
				)
			)
		},
		text: function () {
			let result
			if (this.model.every(node => node.is_folder)) {
				result = this.$tc('documents.delete_folder', this.model.length)
			} else if (this.model.every(node => !node.is_folder)) {
				result = this.$tc('documents.delete_documents', this.model.length)
			} else if (this.model.length > 1) {
				result = this.$t('documents.delete_elements')
			}
			return result
		}
	},
	methods: {
		...mapMutations({
			setCurrentDialog: 'dialogs/setCurrent',
		}),
		isSingleInstanceEventListener: function () {
			return true
		},
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					keyboardEvent: this.keyboard.events.keyDown,
					actions: [
						{
							key: this.keyboard.delete,
							action: this.doShowDeleteDialog
						},
						{
							key: this.keyboard.backspace,
							modifiers: [this.keyboard.modifiers.meta],
							os: [this.OS.MACOS],
							action: this.doShowDeleteDialog
						}
					]
				}
			]
		},
		doShowDeleteDialog: function () {
			if (this.pauseKeyboardListening) {
				return;
			}
			if (this.currentDialog !== null && this.currentDialog !== name) {
				return;
			}
			if (!this.canDelete) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('ecm.cannot_delete_readonly_or_validated'))
				return;
			}

			this.setCurrentDialog(name)
			this.showDeleteDialog = true
		},
		closeDialog: function () {
			this.setCurrentDialog(null)
			this.showDeleteDialog = false
		}
	}
}
</script>
